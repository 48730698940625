'use client';

import cx from 'classnames';
import ReactMarkdown, { type Components } from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import remarkGfm from 'remark-gfm';

import { Link } from '~/v1/components/link/link';

import styles from './markdown.module.scss';

type MarkdownProps = {
  children: string;
  className?: string;
  customComponents?: Components;
};

const Markdown: React.FC<MarkdownProps> = ({
  children,
  className,
  customComponents,
  ...htmlProps
}) => {
  const defaultComponents = {
    table: () => null,
    hr: () => null,
    pre: () => null,
    blockquote: () => null,
    a: ({ ...props }) => (
      <Link {...htmlProps} href={props.href as string} className={styles.link}>
        {props.children}
      </Link>
    ),
    p: ({ ...props }) => (
      <p {...htmlProps} className={cx(styles.paragraph, 'paragraphTypography')}>
        {props.children}
      </p>
    ),
    h1: ({ ...props }) => (
      <h2 {...htmlProps} className={cx(styles.heading1, 'modulesTitleTypography')}>
        {props.children}
      </h2>
    ),
    h2: ({ ...props }) => (
      <h3 {...htmlProps} className={cx(styles.heading2, 'bodyLargeTypography')}>
        {props.children}
      </h3>
    ),
    h3: ({ ...props }) => (
      <h4 {...htmlProps} className={cx(styles.heading3, 'paragraphTypography', styles.bold)}>
        {props.children}
      </h4>
    ),
    li: ({ ...props }) => (
      <li {...htmlProps} className="paragraphTypography">
        {props.children}
      </li>
    ),
  };

  const components = {
    ...defaultComponents,
    ...customComponents,
  };

  return (
    <div className={cx(styles.markdown, className)}>
      <ReactMarkdown components={components} remarkPlugins={[remarkBreaks, remarkGfm]}>
        {children}
      </ReactMarkdown>
    </div>
  );
};

export default Markdown;
